<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <h6>
       {{chartTitle}}
      </h6>
      </div>
    </b-card-header>
    <b-card-body>
        <div v-if="cseries.length>0">
            <vue-apex-charts type="pie" height="350" :options="chartOptions" :series="series"/> 
        </div>
        <div class="text-center text-success" v-else>
        <b-spinner  style="width: 3rem; height: 3rem;"/>
        </div>
     
         
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,BModal,BCardText,BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  props:["chartTitle","cseries","labels","color","numerograph"],
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,BModal,BCardText,BSpinner
  },
  data() {
    return {
         series: this.cseries,
          chartOptions: {
            chart: {
              type: 'pie',
            },
            labels: this.labels,
            theme: {
              monochrome: {
                enabled: true
              }
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -5
                }
              }
            },
            dataLabels: {
              formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [val.toFixed(1) + '%']
              }
            },
            legend: {
              show: false
            }
          },
       
    }
  }
}
</script>
