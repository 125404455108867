<template>
<div>
  <b-row >
    <b-col>
      <div class="text-right mb-1">
          <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="refreshData"
    >
    <feather-icon
    icon="RefreshCwIcon"
    />
    </b-button>
    </div>
    </b-col>
  </b-row>
  <b-row class="match-height">
      <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="'$'+solprice"
          statistic-title="SOL Price"
        />
      </b-col>
     <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="numberWithCommas(tcount)"
          statistic-title="Transaction Count"
        />
      </b-col>
       <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
          color="danger"
          icon="CalendarIcon"
          :statistic="numberWithCommas(slotcount)"
          statistic-title="Slot Current Number"
        />
      </b-col>
       <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
        color="warning"
          icon="CalendarIcon"
          :statistic="epoch"
          statistic-title="Current Epoch"
        />
      </b-col>
        <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="inflation"
          statistic-title="Inflation Rate"
        />
      </b-col>
       <b-col lg="4" md="6" sm="12">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="numberWithCommas(blockH)"
          statistic-title="Block Height"
        />
      </b-col>
  </b-row>
  <graphics/>
  <b-row>
    <b-col cols="12">
       <table-tokens/>
    </b-col>
  </b-row>
  <balances/>
  </div>
</template>

<script>
import { BCard, BCardText,BRow, BCol, BTable, BPagination,BButton } from 'bootstrap-vue'
import CardAreaLarge from "@/components/cards/CardAreaLarge.vue";
import Price from "@/views/Price.vue";
const CoinpaprikaAPI = require('@coinpaprika/api-nodejs-client');
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import TableTokens from './TableTokens.vue';
import Graphics from "@/components/Graphics.vue"
import Balances from "@/components/Balances.vue"
import Ripple from "vue-ripple-directive"
import router from '@/router';
const axios = require("axios");
export default {
  directives:{Ripple},
  components: {
    BCard,
    BCardText,
    BRow, BCol, BTable, BPagination,CardAreaLarge,Price,StatisticCardHorizontal,
    TableTokens,Graphics,BButton,Balances
  },
    data:function(){
     return {
       fields: [
         {key: "time_close", label: "Dates" },
       { key: "close", label: "Balance" }
      ],
      aum:[],
      dias:[],
    amounts:[],
    balance:{},
    solprice:0,
    start:new Date().toISOString().slice(0,10),
    end:"2022-07-30",
    priceBTC:0,
    tcount:0,
    slotcount:0,
    epoch:0,
    inflation:0,
    blockH:0,
    tokens:[],
    }

  },
  mounted:async function(){
        await this.getTickers();
        await this.chainInfo()
    await this.getInflationRate();
 
  },
  methods:{
    getTickers:async function(){
       const vm=this;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response=await axios.get("https://api.solscan.io/market?symbol=SOL&cluster=",config);
 // console.log(response)
  vm.solprice=response.data.data.priceUsdt
    },
    chainInfo:async function(){
      const vm=this; 
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response=await axios.get("https://public-api.solscan.io/chaininfo/",config);
   vm.tcount=response.data.transactionCount
      vm.slotcount=response.data.absoluteSlot
      vm.epoch=response.data.currentEpoch
      vm.blockH=response.data.blockHeight
    },

/*transactionsCount:async function(){
const web3 = require("@solana/web3.js");
(async () => {
 // const solana = new web3.Connection("https://api.mainnet-beta.solana.com");
   const solana = new web3.Connection("https://patient-sparkling-field.solana-mainnet.quiknode.pro/df427b893f9d1fbe868253670c14e8adea8d16e6/");
 
  this.tcount=await solana.getTransactionCount();
})();

},*/
getInflationRate:async function(){
  const vm=this; 
const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = {
    jsonrpc: "2.0",
    id: 1,
    method: "getInflationRate",
  };
  const response=await axios.post("https://patient-sparkling-field.solana-mainnet.quiknode.pro/df427b893f9d1fbe868253670c14e8adea8d16e6/", data, config);
  vm.inflation=response.data.result.total;
 
},
refreshData:function(){
 router.go(0)
},
  numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  }
}
</script>