<template>
  <b-card :title="title">
    <!-- echart -->
    <app-echart-doughnut :series="series" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  props:["data","title"],
  components: {
    BCard,
    AppEchartDoughnut,
  },
  data() {
    return {
      series: [
        {
          name: 'Exchanged',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
           label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          formatter:'{b}\n{c}',
          show: true,
          fontSize: '20',
          color:'#fff',
        }
      },
      labelLine: {
        show: false
      },
            
          data:this.data
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
</style>
