<template>
<div>
    <b-row class="match-height">
    <b-col lg="6" md="12" sm="12">
      <apex-line-area-chart :chartTitle="'Daily Success Vote vs Non-Vote Transaction'"
       :cseries="seriesDailySuccessVote" :labels="labelsDailySuccessVote" :key="cambio" :estado="false" :numerograph="1"/>
    </b-col>
      <b-col lg="6" md="12" sm="12">
      <apex-line-area-chart :chartTitle="'Daily Successful non-vote Transactions & Instructions'"
       :cseries="seriesDailySuccessfullVote" :labels="labelsDailySuccessfullVote" :key="cambio1" :estado="true" :numerograph="2"/>
    </b-col>
  </b-row>
  <b-row class="match-height">
    <b-col lg="6" md="12" sm="12">
      <apex-line-area-chart :chartTitle="'Daily Active Wallets'" :cseries="seriesDailyActiveWallets" 
      :labels="labelsDailyActiveWallets" :key="cambio2" :estado="true" :numerograph="3"/>
    </b-col>
     <b-col lg="6" md="12" sm="12">
      <apex-multipe-bar-chart :chartTitle="'Daily New Token Accounts'" :cseries="seriesDailyNewTokenAccounts" 
      :labels="labelsDailyNewTokenAccounts" :key="cambio3" :color="['#00E396']" :numerograph="4"/>
    </b-col>
  </b-row>
  <b-row class="match-height">
    <b-col >
        <apex-multipe-bar-chart :chartTitle="'Daily New SPL tokens'" :cseries="seriesDailyNewSPLtokens" 
        :labels="labelsDailyNewSPLtokens" :key="cambio4" :color="['#00E396']" :numerograph="5"/>
    </b-col>
      <!--b-col lg="6" md="12" sm="12">
        <apex-line-area :chartTitle="'Daily SPL Token Transfers'" :cseries="seriesDailySPLTokenTransfers"
        :labels="labelsDailySPLTokenTransfers" :key="cambio5" :numerograph="6"/>
    </b-col-->
  </b-row>
  <b-row>
    <!--b-col>
           <apex-line-area-chart :chartTitle="'Daily Network Fees for Successful non-vote Transactions'"
       :cseries="seriesDailyNetworkFees" :labels="labelsDailyNetworkFees" :key="cambio6" :estado="true" :numerograph="7"/>
    </b-col>
  </b-row>
  <b-row class="match-height">
        <b-col lg="6" md="12" sm="12">
          <apex-line-area-chart :chartTitle="'Hourly Number of Blocks'"
       :cseries="seriesHourlyNumberBlocks" :labels="labelsHourlyNumberBlocks" :key="cambio7" :estado="true" :numerograph="8"/>
    </b-col>
      <b-col lg="6" md="12" sm="12">
        <apex-multipe-bar-chart :chartTitle="'Daily Active Vote Accounts'" :cseries="seriesDailyVoteAccounts" 
        :labels="labelsDailyVoteAccounts" :key="cambio8" :color="['#00E396']" :numerograph="9"/>
    </b-col-->
  </b-row>
  <!-- <b-row>
    <b-col>
         <apex-line-area :chartTitle="'Hourly Transactions per Second (TPS) and Success Rate'" 
         :cseries="seriesHourlyTransactionsPerSeconds"
        :labels="labelsHourlyTransactionsPerSeconds" :key="cambio9" :numerograph="10"/>
    </b-col>
  </b-row> -->
</div>
    
</template>
<script>
import {BRow,BCol} from "bootstrap-vue"
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexDataBarChart from "@/components/charts/apex-chart/ApexDataBarChart.vue"
import ApexLineArea from "@/components/charts/apex-chart/ApexLine&Area.vue"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
import {LAMPORTS_PER_SOL} from "@solana/web3.js";
const axios = require("axios");
export default {
    components:{BRow,BCol,ApexLineAreaChart,ApexDataBarChart,ApexLineArea,ApexMultipeBarChart},
    data(){
        return{
    seriesDailySuccessVote:[],
    labelsDailySuccessVote:[],
    seriesDailySuccessfullVote:[],
    labelsDailySuccessfullVote:[],
    seriesDailyActiveWallets:[],
    labelsDailyActiveWallets:[],
    seriesDailyNewTokenAccounts:[],
    labelsDailyNewTokenAccounts:[],
    seriesDailyNewSPLtokens:[],
    labelsDailyNewSPLtokens:[],
    seriesDailySPLTokenTransfers:[],
    labelsDailySPLTokenTransfers:[],
    seriesDailyNetworkFees:[],
    labelsDailyNetworkFees:[],
    maxDailyNetworkFees:0,
    seriesHourlyNumberBlocks:[],
    labelsHourlyNumberBlocks:[],
    seriesDailyVoteAccounts:[],
    labelsDailyVoteAccounts:[],
    seriesHourlyTransactionsPerSeconds:[],
    labelsHourlyTransactionsPerSeconds:[],
    cambio:0,
    cambio1:0,
    cambio2:0,
    cambio3:0,
    cambio4:0,
    cambio5:0,
    cambio6:0,
    cambio7:0,
    cambio8:0,
    cambio9:0,

        }
    },
    mounted: async function(){
     await this.getDailySuccessVote()
     await this.getDailySuccessfulVote()
     await this.DailyActiveWallets()
     await this.DailyNewTokenAccounts()
     await this.DailyNewSPLtokens()
    // this.DailySPLTokenTransfers()
    // this.DailyNetworkFees()
     //this.HourlyNumberBlocks()
     //this.DailyVoteAccounts()
    // await this.HourlyTransactionsPerSeconds()
    },
    methods:{
     getDailySuccessVote: async function() {
   const vm=this
  vm.cambio=1
  var arr=[]
  var arr1=[]
  var arr2=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  const response=await axios.post("/api/v1/sel/gvtsc",config);
      for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][2])
        arr1.push(response.data.rows[x][1])
        arr2.push(d)         
      }
       vm.seriesDailySuccessVote.push({name:'Non-Vote Transaction',data:arr})
        vm.seriesDailySuccessVote.push({name:'Vote Transaction',data:arr1})
        vm.labelsDailySuccessVote=arr2
      vm.cambio=2

    },
    getDailySuccessfulVote:async function(){
    const vm=this
  vm.cambio1=1
  var arr=[]
  var arr1=[]
  var arr2=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
  }
  const config = {
    headers: {
      "Content-Type": "application/json"
      }};
     const response= await axios.post("/api/v1/sel/gnvtsc",config);
    for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push(response.data.rows[x][2])
        arr2.push(d)         
      }
          vm.seriesDailySuccessfullVote.push({name:'Transactions',data:arr})
        vm.seriesDailySuccessfullVote.push({name:'Instructions',data:arr1})
        vm.labelsDailySuccessfullVote=arr2
        vm.cambio1=2
    },
  DailyActiveWallets:async function(){
        
  const vm=this
  vm.cambio2=1
  var arr=[]
  var arr1=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  const response= await axios.post("/api/v1/sel/gdawllt",config);

  for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push(d)         
      }
      vm.seriesDailyActiveWallets.push({name:'Active Wallets',data:arr})
      vm.labelsDailyActiveWallets=arr1
      vm.cambio2=2
    },
    DailyNewTokenAccounts:async function(){
  const vm=this
  vm.cambio3=1
  var arr=[]
  var arr1=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  const response=await axios.post("/api/v1/sel/gdntkn",config);
     for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push(d)         
      }
      vm.seriesDailyNewTokenAccounts.push({name:'New Token Account',data:arr})
      vm.labelsDailyNewTokenAccounts=arr1
      vm.cambio3=2
    },
    DailyNewSPLtokens:function(){
  const vm=this
  vm.cambio4=1
  var arr=[]
  var arr1=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/gdnsptkn",config).then(function (response) {
      for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push(d)         
      }
      vm.seriesDailyNewSPLtokens.push({name:'New SPL Tokens',data:arr})
      vm.labelsDailyNewSPLtokens=arr1
      vm.cambio4=2
    })
    .catch((err) => {
      console.log(err);
    });
    },
   /* DailySPLTokenTransfers:function(){
  const vm=this
  vm.cambio5=1
  var arr=[]
  var arr1=[]
  var arr2=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/gdsptkt",config).then(function (response) {
      for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][2]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][0])
        arr1.push(response.data.rows[x][1])
        arr2.push(d)         
      }
      vm.seriesDailySPLTokenTransfers.push({name:'Token Transfers',type:'area',data:arr})
      vm.seriesDailySPLTokenTransfers.push({name:'Active Token Accounts',type:'line',data:arr1})
      vm.labelsDailySPLTokenTransfers=arr2
      vm.cambio5=2
    })
    .catch((err) => {
      console.log(err);
    });
    },
    DailyNetworkFees:function(){
  const vm=this
  vm.cambio6=1
  var arr=[]
  var arr1=[]
  var max=0
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/gdntwf",config).then(function (response) {
     for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][1]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push((response.data.rows[x][0])/LAMPORTS_PER_SOL)
        max=Math.max((response.data.rows[x][0])/LAMPORTS_PER_SOL,max)
        arr1.push(d)         
      }
      vm.seriesDailyNetworkFees.push({name:'Fee (SOL)',data:arr})
      vm.labelsDailyNetworkFees=arr1
      vm.maxDailyNetworkFees=(max).toFixed(0)

      vm.cambio6=2
    })
    .catch((err) => {
      console.log(err);
    });

    },
    HourlyNumberBlocks:function(){
  const vm=this
  vm.cambio7=1
  var arr=[]
  var arr1=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/gdnobk",config).then(function (response) {
     for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push(d)         
      }
      vm.seriesHourlyNumberBlocks.push({name:'Block Count',data:arr})
      vm.labelsHourlyNumberBlocks=arr1
      vm.cambio7=2
    })
    .catch((err) => {
      console.log(err);
    });
    },
    DailyVoteAccounts:function(){
  const vm=this
 vm.cambio8=1
  var arr=[]
  var arr1=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/gdvtac",config).then(function (response) {
     for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][1]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][0])
        arr1.push(d)         
      }
      vm.seriesDailyVoteAccounts.push({name:'Vote Accounts',data:arr})
      vm.labelsDailyVoteAccounts=arr1
      vm.cambio8=2
    })
    .catch((err) => {
      console.log(err);
    });
    },*/
    HourlyTransactionsPerSeconds:function(){
  const vm=this
  var arr=[]
  var arr1=[]
  var arr2=[]
  const options = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
}
  const config = {
    headers: {
      "Content-Type": "application/json"
      },
  };
  axios.post("/api/v1/sel/ghtps",config).then(function (response) {
      for (let x = 0; x < response.data.rows.length; x++) {
        const date = new Date(response.data.rows[x][0]) 
      var d=new Intl.DateTimeFormat('en-US', options).format(date)
        arr.push(response.data.rows[x][1])
        arr1.push((response.data.rows[x][2])*100)
        arr2.push(d)         
      }
      vm.seriesHourlyTransactionsPerSeconds.push({name:'TPS',type:'area',data:arr})
      vm.seriesHourlyTransactionsPerSeconds.push({name:'Success Rate',type:'line',data:arr1})
      vm.labelsHourlyTransactionsPerSeconds=arr2
      vm.cambio9=1
    })
    .catch((err) => {
      console.log(err);
    });
    },

    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
