<template>
    <div>
        <!--b-row class="match-height">
            <b-col>
                <apex-mono-pie :chartTitle="'Top Large Accounts Balances'" :cseries="serieslargeBalances"
                :labels="labelslargeBalances"/>
            </b-col>
              <b-col>
                <apex-mono-pie :chartTitle="'Top Large Tokens Balances'" :cseries="seriestokenLargeBalances"
                :labels="labelstokenLargeBalances"/>
            </b-col>
        </b-row-->

  <b-row class="match-height">
   <b-col cols="12">
        <apex-tree-map :info="infoExchange" :title="'Exchanged by SOL/USD'" :key="cambio"/>
    </b-col>
  </b-row>

    </div>
</template>
<script>
import {BRow,BCol} from "bootstrap-vue"
import EchartDoughnut from "@/components/charts/EchartDoughnut.vue";
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
import ApexMonoPie from "@/components/charts/apex-chart/ApexMonoPie.vue"
import ApexTreeMap from "@/components/charts/apex-chart/ApexTreeMap.vue"
export default {
    components:{BRow,BCol,EchartDoughnut,ApexMultipeBarChart,ApexMonoPie,ApexTreeMap},
    data(){
        return{
        largeBalances:[],
        serieslargeBalances:[],
        labelslargeBalances:[],
        tokenLargeBalances:[],
        seriestokenLargeBalances:[],
        labelstokenLargeBalances:[],
        infoExchange:[],
        cambio:0

        }
    },
    mounted(){   
   // await this.getTokenLargestAccount();
     //await this.getLargeAccounts();
       this.getExchanged();
    },
    methods:{
            getExchanged:function(){
      const vm=this;
        var arr=[]
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://api.coinpaprika.com/v1/coins/sol-solana/markets",config) .then(function (response) {
      for (var x = 0; x < response.data.length; x++) {
          if(response.data[x].pair=="SOL/USD"){
             vm.infoExchange.push({x:response.data[x].exchange_name,y:response.data[x].quotes.USD.price})
          }
      }
      vm.cambio=1
    })
    .catch((err) => {
      // handle error
      console.log(err);
    }); 
    },
       getTokenLargestAccount:async function(){
      const vm=this;
        const web3 = require("@solana/web3.js");

  const url = "https://patient-sparkling-field.solana-mainnet.quiknode.pro/df427b893f9d1fbe868253670c14e8adea8d16e6/";
  const solana = new web3.Connection(url);
  const dts=await solana.getTokenLargestAccounts(
      new web3.PublicKey("So11111111111111111111111111111111111111112")
    );
  for (var x = 0; x < 11; x++) {
      vm.tokenLargeBalances.push({value:dts.value[x].amount,
      name:dts.value[x].address.toString()}); 
      vm.seriestokenLargeBalances.push(dts.value[x].uiAmount)
      vm.labelstokenLargeBalances.push(dts.value[x].address.toString())
    }
    },
    getLargeAccounts:function(){
         const vm=this;
     const axios = require("axios");
     let formData={
       "method":"getLargestAccounts","jsonrpc":"2.0","params":
       [{"commitment":"finalized"}],
       "id":"59582ea4-8538-4983-98c7-cc2482627aa9"}
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post("https://patient-sparkling-field.solana-mainnet.quiknode.pro/df427b893f9d1fbe868253670c14e8adea8d16e6/",formData,config) .then(function (response) {
    console.log(response.data.result.value);
    for (var x = 0; x < 11; x++) {
      vm.largeBalances.push({value:response.data.result.value[x].lamports,
      name:response.data.result.value[x].address}); 
      vm.serieslargeBalances.push(response.data.result.value[x].lamports)
      vm.labelslargeBalances.push(response.data.result.value[x].address)
    }
    })
    .catch((err) => {
      // handle error
      console.log(err);
    });

    },

    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>