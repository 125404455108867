<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{title}}</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
            variant="light-warning"
            >
              <feather-icon
                size="18"
                icon="BoldIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Bitcoin
            </h6>
            <small>Solana price in BTC</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-success"
        >
          {{balance.price_btc}}
        </div>
      </div>
       <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-warning"
            >
              <feather-icon
                size="18"
                icon="DollarSignIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Dollar USD
            </h6>
            <small>Solana price in USD</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-success"
        >
          {{balance.price_usd}}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
    props:["balance","title"],
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
}
</script>
