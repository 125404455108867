<template>
  <e-charts
    ref="line"
     autoresize
    :options="option"
    theme="theme-color"
    auto-resize
  />
</template>
<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/grid'
import 'echarts/lib/chart/line'
import theme from '@core/components/charts/echart/theme.json'
ECharts.registerTheme('theme-color', theme) 
export default {
  props:["dias","aum"],
  components: {
    ECharts
  },

  data() {
return {
      option: {
   tooltip: {
    trigger: 'axis',
    position: function (pt) {
      return [pt[0], '10%'];
    }
  },
  title: {
    left: 'center',
    text: 'Large Area Chart'
  },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      }
    }
  },
     grid: {
          left: '60px',
          right: '40px',
          bottom: '60px',
        },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: this.dias,
        splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
    boundaryGap: [0, '100%'],
      splitLine: {
      show: false
    }
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100
    },
    {
      start: 0,
      end: 100
    }
  ],
  series: [
    {
      name: 'Total',
      type: 'line',
      symbol: 'none',
      sampling: 'lttb',
      itemStyle: {
        color: 'rgb(255, 70, 131)'
      },
      areaStyle: {
        color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgb(255, 158, 68)'
          },
          {
            offset: 1,
            color: 'rgb(255, 70, 131)'
          }
        ])
      },
      data: this.aum
    }
  ]
      },
    }
  },
}
</script>
<style>
.echarts {
  width: 100% !important;
}
</style>