<template>
      <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
      <b-card-title>Tokens</b-card-title>
    </b-card-header>
    <b-table
      :items="items"
      :fields="fields"
      striped
      responsive
      hover
      small
       @row-clicked="getTokenAddress"
    >
      <template #cell(icon)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(holder)="data">
        {{numberWithCommas(data.value)}}
      </template>
         <template #cell(coingeckoInfo.marketData.priceChangePercentage24h)="data">
             <sup :class="'font-weight-bold '+colorArrow(data.value)+''">
                    <feather-icon 
                    :icon="data.value>0?'ArrowUpIcon':'ArrowDownIcon'"
                    size="14"
                    />
                    </sup>
                        {{formatterPercent(data.value)}}
      </template>
        <template #cell(coingeckoInfo.marketData.priceChangePercentage7d)="data">
              <sup :class="'font-weight-bold '+colorArrow(data.value)+''">
                    <feather-icon 
                    :icon="data.value>0?'ArrowUpIcon':'ArrowDownIcon'"
                    size="14"
                    />
                    </sup>
        {{formatterPercent(data.value)}}
      </template>
        <template #cell(marketCapFD)="data">
        {{'$'+numberWithCommas(data.value)}}
      </template>
    </b-table>
    </b-card>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge,BCardTitle,BCardHeader
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,BCardTitle,BCardHeader
  },
  data() {
    return {
      fields: [{ key: 'icon', label: '' }, {key:'tokenName',label:'Token'}, 
      {label:'',key:'tokenSymbol'},{label:'Holders',key:'holder'},{label:'Price',key:'priceUst'}, 
      {label:'24H',key:'coingeckoInfo.marketData.priceChangePercentage24h'},
       {label:'7D',key:'coingeckoInfo.marketData.priceChangePercentage7d'},
      {label:'Market Cap',key:'marketCapFD'}],
      items:[],
      offset:0
    }
  },
  mounted(){
      this.getTokens();
  },
  methods:{
        getTokens:async function() {
      const vm=this;
      var salir=true
      var arr=[]
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
   while (salir) {
       const response=await axios.get("https://api.solscan.io/tokens?offset="+vm.offset+"&limit=50&sortby=alphaVolume&sorttype=desc&cluster=",config)
         for (let x = 0; x < response.data.data.tokens.length; x++) {
            if (response.data.data.tokens[x].tokenName=="DUST Protocol"
            ||response.data.data.tokens[x].tokenName=="FORGE") {      
              arr.push(response.data.data.tokens[x]); 
              if (arr.length==2){
                salir=false
              }
            } 
        }
        vm.offset+=50 
   }  
      this.items=arr
    },

    getTokenAddress:function(item){
       let  dataU={tokenaddress:item.mintAddress};
       localStorage.setItem('dataadmin',null);
       localStorage.removeItem('datadmin'); 
       localStorage.setItem('dataadmin',JSON.stringify(dataU));
      this.$router.push({ name: 'tokendetails'})

    },
     formaterNumber: function (params) {
      var pow = Math.pow,
        floor = Math.floor,
        abs = Math.abs,
        log = Math.log;
      var n = params;
      var abbrev = "KMB";
      var base = floor(log(abs(n)) / log(1000));
      var suffix = abbrev[Math.min(2, base - 1)];
      base = abbrev.indexOf(suffix) + 1;
      var n2 = n / pow(1000, base);
      var prec = Math.pow(10, 2);
      var n3 = Math.round(n2 * prec) / prec;
      return suffix ? "$" + n3 + suffix : "$" + n;
    },
      numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatterPercent:function(v){
        return (v).toFixed(2)+"%";
    },
    colorArrow:function(n){
        if(n>0){
            return 'text-success';
        }
        return 'text-danger';    
    }
  } 
}
</script>
 