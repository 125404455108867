<template>
  <b-card >
    <b-card-header>
      <div>
            <b-card-title>
       {{title}}
    </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
         <vue-apex-charts
      type="treemap"
      height="350"
      :options="chartOptions"
      :series="series"
    />
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard, BCardTitle, BCardSubTitle,BCardHeader,VBTooltip,BRow,BCardBody} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'

export default {
    props:["info","title"],
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,BRow,BCardBody
  },
    directives: {
     'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
        series: [
            {
              data:this.info
            }
          ],
          chartOptions: {
          /*  dataLabels:{
            enabled: true,
                style: {
    colors: ['#fff', '#fff', '#fff']
  }
            },*/
            legend: {
              show: false
            },
            chart: {
                 height: 350,
              type: 'treemap',
              toolbar:{
                show:false
              }
            },

             plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false
              }
            }
          },
    }
        
    },
}
</script>
