<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <h6>
       {{chartTitle}}
      </h6>
      </div>
        <div class="d-flex align-items-center">
        <feather-icon
          icon="DownloadIcon"
            @click="modalShow=!modalShow"
          size="16"
           class="cursor-pointer"
        />
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
          <b-modal
        v-model="modalShow"
      title="Download Chart"
      ok-only
      centered
      ok-title="Accept"
      @ok="downloadExcel"
    >
      <b-card-text>
      Do you want to download the chart data on a csv?
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,BModal,BCardText
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  props:["chartTitle","cseries","labels","color","numerograph"],
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,BModal,BCardText
  },
  data() {
    return {
       modalShow: false,
        series: this.cseries,
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
         animations: {
        enabled: false
    }
      },
         colors: this.color,
      plotOptions: {
        bar: {
           barHeight: '30%',
          endingShape: 'rounded',
          
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
         yaxis: {
          lines: {
            show: false,
          },
        },
      },
      
      xaxis: {
        categories: this.labels,
         tickAmount:2,
           labels: {
                  rotate: 0,
                  rotateAlways: false,
              }
      },
     yaxis:{
              labels:{
               formatter: function (params) {
      var pow = Math.pow,
        floor = Math.floor,
        abs = Math.abs,
        log = Math.log;
      var n = params;
      var abbrev = "KMB";
      var base = floor(log(abs(n)) / log(1000));
      var suffix = abbrev[Math.min(2, base - 1)];
      base = abbrev.indexOf(suffix) + 1;
      var n2 = n / pow(1000, base);
      var prec = Math.pow(10, 2);
      var n3 = Math.round(n2 * prec) / prec;
      return suffix ?n3+suffix :n; 
      }
      }
        },
      fill: {
        opacity: 1,
      },
    },

    }
  },
  methods:{
    downloadExcel:function(){
       var ex=null;
       const axios = require("axios");
       const vm=this
   let formData = {
          option :vm.numerograph
        }
  const config = {
    headers: {
      "Content-Type": "application/json"
      }};
  axios.post("/api/v1/sel/gdgrphcs",formData,config).then(function (response) {
    ex=response.data
      const url = window.URL.createObjectURL(new Blob([ex]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", vm.chartTitle+'.csv');
        document.body.appendChild(link);
        link.click();

    })
    .catch((err) => {
      console.log(err);
    });
    }
  }
}
</script>
