<template>
  <b-card>
    <b-card-header>
      <h4 class="mb-0">
        {{title}}
      </h4>
    </b-card-header>
    <b-row>
        <echart-area-large :dias="dias" :aum="aum"/>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue';
import EchartAreaLarge from "@/components/charts/EchartAreaLarge.vue";
export default {
  props:["dias","aum","title"],
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    EchartAreaLarge
  },

  data() {      
    return {
    }
  },
}
</script>
